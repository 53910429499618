<template>
  <v-fade-transition mode="out-in" leave-absolute>
    <div class="tutorial">
      <div class="tutorial-step-container">
        <v-btn class="tutorial-close-btn" icon @click="$emit('close-tutorial')">
          <v-icon size="30">fas fa-times</v-icon>
        </v-btn>
        <div class="tutorial-step">
          <div class="tutor-image download">
            <canvas id="qrCode" class="tutor-canvas"></canvas>
          </div>
          <div class="description">
            <div class="step-title">1. DOWNLOAD THE APP</div>
            <div class="step-subtitle">OPEN YOUR PHONE'S CAMERA AND SCAN!</div>
          </div>
        </div>

        <div class="tutorial-step">
          <div class="tutor-image">
            <v-img src="~@/assets/tutorial-phone.png" height="120" contain />
          </div>
          <div class="description">
            <div class="step-title">2. LOGIN WITH YOUR USERNAME</div>
            <div class="step-subtitle">SAME USERNAME AS THE MOBILE APP</div>
          </div>
        </div>

        <div class="tutorial-step">
          <div class="tutor-image">
            <v-img src="~@/assets/tutorial-record.png"  height="120" contain />
          </div>
          <div class="description">
            <div class="step-title">3. BROADCAST!</div>
            <div class="step-subtitle">SAME USERNAME AS THE MOBILE APP</div>
          </div>
        </div>
      </div>
    </div>
  </v-fade-transition>
</template>

<script>
import QRCode from 'qrcode';

export default {
  mounted() {
    let canvas = document.getElementById('qrCode');
    if (canvas == null) return;
    let link = localStorage.getItem('dynamic-link');

    QRCode.toCanvas(canvas, link, {
      width: 100,
      margin: 2
    }, function (error) {
      if (error) console.error(error)
    });
  }
  
};
</script>


<style scoped lang="scss">
.tutorial {
  position: absolute;

  width: 100%;
  height: 100%;

  z-index: 9;

  background-color: rgba(0, 0, 0, 0.8);

  display: flex;

  justify-content: center;
  align-items: center;
}

.tutorial-step-container {
  padding: 2rem;
  position: relative;

  box-sizing: border-box;

  background: rgba(0, 0, 0, 0.543271);

  border: 1px solid #ffffff;
  border-radius: 12px;

  height: 70%;
  // min-width: 40%;

  display: flex;
  flex-flow: column nowrap;;

  justify-content: space-around;
  .tutorial-close-btn {
    position: absolute;
    font-size: 12px;
    right: 10px;
    top: 10px;
  }
}

.tutorial-step {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 120px auto;

  justify-content: flex-start;
  align-items: center;

  .download {
    padding: 5px;
    min-width: 100px;
    border-radius: 12px;
    border: 1px solid #FFFFFF;
  }

  & > * {
    margin: 1rem;
  }

  .tutor-image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 120px;
  }

  .tutor-canvas {
    padding: 3px
  }

  .description {
    text-transform: uppercase;
  }

  .step-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: -0.925714px;
  }

  .step-subtitle {
    font-weight: normal;
    font-size: 12px;
    line-height: 30px;
    letter-spacing: 0.666667px;
  }
}

</style>
