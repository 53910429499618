<template>
  <div class="wrapper">
    <!-- <div class="buttons">
      <v-btn @click="jump()">
        Jump to show
      </v-btn>
    </div> -->
    <user-profile-menu />
    <iframe  :src="url" ref="frame" id="pod-stream">
    </iframe >
  </div>
</template>

<script>
import awsAuth from '@/cognito/aws-auth'
import UserProfileMenu from '../layout/PageProfileMenu.vue';
import loader from "@/config.loader";

export default {
  props: {
    project: { type: String },
    env: { type: String },
    isAdmin: { type: Boolean, default: false },
    changePodValue: { type: Boolean, default: false },
    musicVolume: {type: Number, default: 40 }
  },
  components: {
    UserProfileMenu,
  },
  data () {
    return {
      userInfo: null
    }
  },
  computed: {
    url() {
      const params = [];

      if (this.project) {
        params.push(`projectId=${this.project}`);
      }

      if (this.env) { 
        params.push(`environmentId=${this.env}`);
      }

      const query = params.length > 0
        ? '?' + params.join('&')
        : '';

      return `${this.baseUrl}/${query}`;
    },
    baseUrl() {
      return loader.getConfigValue("VUE_PW_IFRAME_URL");
    }
  },
  methods: {
    resizeFrame(e){
      if(e){
        this.sendWindowSize();
      }
    },
    sendWindowSize(){
      let width = this.$refs.frame.scrollWidth;
      let height = this.$refs.frame.scrollHeight;
      this.sendMessage({ type: "change.window.size", width: width, height: height})
    },
    sendMusicVolume(value) {
      const volume = (value === 0) ? 0.001 : value / 100;
      this.sendMessage({type: 'user.chat.volume', value: volume})
    },
    sendMessage(message) {
      this.$refs.frame.contentWindow.postMessage(message, '*');
    },
    jump(){
      awsAuth.refreshJWTToken()
        .then(result=> {
          console.log('concert')
          this.sendMessage({ type: 'user.connect', userId: this.userInfo.userId, isAdmin: this.isAdmin, token: result.data, concertId: 9987 })
          this.sendMusicVolume(this.musicVolume);
          this.sendWindowSize();

          this.$emit("emergency-available");
        })
    },
    changePod(){
      this.sendMessage({ type: 'user.change.pod.start', value: !this.changePodValue });
      this.$emit("change-map-view", !this.changePodValue);
    },
    handleMessage(event) {
      if (event.origin !== this.baseUrl) {
        return;
      }
      let data = JSON.parse(event.data)
      if (data && data.type === 'unreal.started') {
        this.jump();
      }
    }
  },
  mounted(){
    this.$nextTick(()=>{
      let self = this;
      awsAuth.currentUserInfo()
        .then(response=>{
          self.userInfo = response
          self.$refs.frame        
        })
        .catch(() => {
          self.userInfo = {}
        })
    })
  },
  created(){
    window.addEventListener("resize", this.resizeFrame);
    window.addEventListener("message", this.handleMessage);
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeFrame);
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  height: calc(100% - 1rem);

  // margin: 0 3rem 0 3rem;
  display: flex;
  flex-flow:column nowrap;
  align-items: flex-end;
  
  #pod-stream {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border: none;
  }

  &::v-deep .profile {
    z-index: 1;
    // opacity: 0;
    margin: 0px 0px 0 0;
  }

}


::v-deep .btn-logo {
  margin: 5px 0 0 20px;
}

.buttons {
  display: flex;
  flex-flow: row nowrap;

  justify-content: flex-end;
  align-items: center;
  align-self: flex-end;

  padding: 1rem ;
  
  & > * {
    margin: 0 1rem; 
  }

  & > *:first-child {
    margin-left: 0;
  }

  & > *:last-child {
    margin-right: 0;
  }
}

</style>
